import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/userService";
import { logout } from "../../actions/auth";
import { Modal, Button } from "react-bootstrap";
import handleDownloadPDF from "./pdf";
import moment from "moment";

const OrderPage = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  const [myProfileData, setMyProfileData] = useState({});
  const { user: currentUser } = useSelector((state) => state.auth);
  const [showDispatcDetailhModal, setShowDispatchDetailModal] = useState(false);
  const [selectedDispatch, setSelectedDispatch] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch clients from the backend
  useEffect(() => {
    const fetchMyprofile = async () => {
      try {
        const response = await UserService.fetchMyprofile();
        setMyProfileData(response.data.myProfile);
      } catch (error) {
        console.error("Error fetching clients", error);
      }
    };
    fetchMyprofile();
  }, []);

  // Fetch clients from the backend
  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const response = await UserService.fetchOrderHistory();
        console.log(response.data.orders);
        setOrderHistory(response.data.orders);
      } catch (error) {
        console.error("Error fetching clients", error);
      }
    };
    fetchOrderHistory();
  }, []);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
  };

  const handleLogout = () => {
    dispatch(logout())
      .then(() => navigate("/login"))
      .catch((error) => {
        console.log(error);
      });
  };

  const handleViewDispatch = (dispatch, order) => {
    // console.log(dispatch);
    setSelectedOrder(order); // Set the selected order for dispatch
    setSelectedDispatch(dispatch);
    setShowDispatchDetailModal(true);
  };

  if (!currentUser) {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="Type some info" />
          <meta name="author" content="Type name" />

          <title></title>
        </Helmet>
        <Navbar />
        <div className="login-popup d-flex justify-content-center align-items-center vh-100 bg-light">
          <div className="popup-content p-4 bg-white shadow rounded text-center">
            <h3 className="mb-3">Login Required</h3>
            <p className="mb-4">Please log in or register to view your cart.</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary me-2"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/registrationForm")}
              >
                Register
              </button>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />

      <div className="container mt-5 pb-5">
        <h2 className="text-center mb-4">Quotation Page</h2>
        <div className="d-flex justify-content-end">
          <button onClick={handleLogout} className="btn btn-outline-secondary">
            Logout
          </button>
        </div>

        <div className="card p-4 mt-3 shadow-sm">
          <div className="col-md-6 pb-5">
            <div className="form-group">
              <strong>Customer Code: </strong>&nbsp;{" "}
              {myProfileData.user_code_d365}
            </div>
          </div>
          <h5>Order History</h5>
          <table className="table table-hover mt-3">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Sales Or. No.</th>
                <th>Order Date</th>
                <th>Status</th>
                {/* <th>Total Amount</th> */}
              </tr>
            </thead>
            <tbody>
              {orderHistory.map((order, index) => (
                <React.Fragment key={order.order_id + index}>
                  <tr
                    key={order.order_id}
                    onClick={() => handleOrderClick(order)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{order.order_id}</td>
                    <td>{order.sales_order_number}</td>
                    <td>{moment(order.created_at).format("DD MMM YYYY")}</td>
                    <td>
                      <span
                        className={`badge text-white ${
                          order.status === "Approved"
                            ? "bg-success"
                            : order.status === "inprogress"
                            ? "bg-warning"
                            : "bg-danger"
                        }`}
                      >
                        {order.status}
                      </span>
                    </td>
                    {/* <td>₹ {order.order_net_amount}</td> */}
                  </tr>
                  {/* Expanded Row for Dispatches */}
                  {order.dispatches?.length > 0 && (
                    <tr>
                      <td colSpan={3}>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Invoice ID</th>
                              <th>Tracking ID</th>
                              {/* <th>Total Amount</th> */}
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.dispatches.map((dispatch, index) => (
                              <tr key={"dispatch+" + index}>
                                <td> </td>
                                <td>{dispatch.invoice_id}</td>
                                <td>{dispatch.tracking_id}</td>
                                {/* <td>₹{dispatch.dispatch_total_amount}</td> */}
                                <td>
                                  <button
                                    className="btn btn-link text-primary"
                                    onClick={() =>
                                      handleViewDispatch(dispatch, order)
                                    }
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for Order Details */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder ? (
            <>
              <h5>Sales Order No: {selectedOrder.sales_order_number}</h5>
              <h5>Customer Code: {myProfileData.user_code_d365}</h5>
              <p>
                <strong>Status:</strong>
                <span
                  className={`badge text-white ${
                    selectedOrder.status === "Approved"
                      ? "bg-success"
                      : selectedOrder.status === "inprogress"
                      ? "bg-warning"
                      : "bg-danger"
                  }`}
                >
                  {selectedOrder.status}
                </span>
              </p>
              <p>
                <strong>Total Quantity:</strong> {selectedOrder.total_quantity}
              </p>
              {/* <p>
                <strong>Total Amount:</strong> ₹{" "}
                {selectedOrder.order_total_amount}
              </p> */}
              <h6 className="mt-4">Items:</h6>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Product Code</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    {/* <th>Net Amount</th>
                    <th>GST</th>
                    <th>Total Amount</th> */}
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder.items.map((item) => (
                    <tr key={item.order_item_id}>
                      <td>{item.product_code}</td>
                      <td>{item.product_name}</td>
                      <td>{item.item_quantity}</td>
                      {/* <td>₹{item.item_net_amount}</td>
                      <td>₹{item.item_gst}</td>
                      <td>₹{item.item_total_amount}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p>No order selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleDownloadPDF(selectedOrder, myProfileData);
            }}
          >
            Download PDF
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Dispatch Details Modal */}
      <Modal
        show={showDispatcDetailhModal}
        onHide={() => setShowDispatchDetailModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Dispatch Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDispatch && selectedOrder && (
            <div>
              <h5>Order ID: {selectedOrder.id}</h5>
              <p>
                <strong>Order Number:</strong>{" "}
                {selectedOrder.sales_order_number}
              </p>
              <p>
                <strong>Client Code:</strong> {myProfileData.user_code_d365}
              </p>
              <p>
                <strong>Order Date:</strong>{" "}
                {moment(selectedOrder.created_at).format("DD MMM YYYY")}
              </p>
              {/* <p>
                <strong>Total Amount:</strong> ₹{selectedOrder.total_amount}
              </p> */}
              <p>Invoice ID: {selectedDispatch.invoice_id}</p>
              <p>Tracking ID: {selectedDispatch.tracking_id}</p>
              {/* <p>Net Amount: {selectedDispatch.dispatch_net_amount}</p>
              <p>Total Gst: {selectedDispatch.dispatch_total_gst}</p>
              <p>Total Amount: ₹{selectedDispatch.dispatch_total_amount}</p> */}
              {/* Add more dispatch details here */}
              {/* Dispatched Summary */}
              <div className="mt-4">
                <h6>Dispatched Summary</h6>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Product Code</th>
                      <th>Product Name</th>
                      <th>Quantity </th>
                      {/* <th>Net Amt (₹)</th>
                      <th>GST (₹)</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedDispatch.items.map((dispatchedItem, index) => (
                      <tr key={"DispatchedItems-" + index}>
                        <td>{dispatchedItem.product_code}</td>
                        <td>{dispatchedItem.product_name}</td>
                        <td>{dispatchedItem.dispatched_quantity}</td>
                        {/* <td>{dispatchedItem.item_net_amount}</td>
                        <td>{dispatchedItem.item_total_gst}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default OrderPage;
