import React, { useEffect, useState } from "react";
import UserService from "../../services/userService";
import { Link, useNavigate } from "react-router-dom";
import HelperFunction from "../../services/helperFunction";
import BannerCarousel from "./BannerCarousel";
import { useSelector } from "react-redux";

const Content = () => {
  const [banners, setBanners] = useState([]);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleProductClick = (id, brand_name, banner) => {
    // Pass the state (example: a product ID or object) while navigating
    navigate(
      `/shop?brandId=${encodeURIComponent(
        HelperFunction.encrypt(id)
      )}&brandName=${encodeURIComponent(
        HelperFunction.encrypt(brand_name)
      )}/&banner=${banner}`
    );
  };

  useEffect(() => {
    UserService.getBanners().then((response) => {
      setBanners(response.data.banners);
    });
  }, []);

  useEffect(() => {
    UserService.getBrand({ limit: 10 }).then((response) => {
      setBrands(response.data.brands);
    });
  }, []);

  useEffect(() => {
    UserService.getProducts({ limit: 4 }).then((response) => {
      setProducts(response.data.products);
    });
  }, []);

  useEffect(() => {
    UserService.getProducts({ limit: 8 }).then((response) => {
      setFeaturedProducts(response.data.products);
    });
  }, []);

  // const products = [
  //   {
  //     title: "Armchair for Home and Office, Yellow color",
  //     imgSrc: "images/items/interior/1.jpg",
  //     price: "₹113.60",
  //     condition: "Broken",
  //   },
  //   {
  //     title: "Sample product name just demo information here",
  //     imgSrc: "images/items/interior/2.jpg",
  //     price: "₹990.50",
  //     condition: "Good condition",
  //   },
  //   {
  //     title: "Ceramic Jug for Kitchen, Medium size",
  //     imgSrc: "images/items/interior/3.jpg",
  //     price: "₹175.00",
  //     condition: "Used",
  //   },
  //   {
  //     title: "Interior plan for office, Large size",
  //     imgSrc: "images/items/interior/4.jpg",
  //     price: "₹98.00",
  //     condition: "Brand new",
  //   },
  // ];

  return (
    <>
      <BannerCarousel banners={banners} />

      {/* <FloatingWhatsApp accountName="Sonepar India" avatar="" /> */}
      {/* {banners.length > 0 && (
        <section className="">
          <div className="container-fluid px-0">
            <article className="card border-0">
              <picture>
                <source
                  // media="(max-width:1140px)"
                  srcSet={
                    process.env.REACT_APP_BACKEND_SERVER +
                    "/images/" +
                    banners[0].location
                  }
                />
                <img
                  src={
                    process.env.REACT_APP_BACKEND_SERVER +
                    "/images/" +
                    banners[0].location
                  }
                  className="w-100"
                  alt="Interior banner"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                    e.target.src = "/images/default.jpg"; // Set the default image
                  }}
                />
              </picture>
            </article>
          </div>
        </section> 
      )}
*/}
      <section className="padding-top">
        <div className="container">
          <header className="mb-4">
            <h3>Brands</h3>
          </header>

          <nav className="row gy-3">
            {brands.map((brand, index) => (
              <div
                onClick={() =>
                  handleProductClick(brand.id, brand.brand_name, brand.banner)
                }
                className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
                key={index}
              >
                <div
                  className="card hover:border-gray"
                  style={{ height: "133px", width: "200px" }}
                >
                  <img
                    className="img-fluid w-100 h-100"
                    src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${brand.brandImage}`}
                    alt={brand.brand_name}
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                      e.target.src = "/images/default.jpg"; // Set the default image
                    }}
                  />
                </div>
              </div>
            ))}
          </nav>
        </div>
      </section>

      <section className="padding-top">
        <div className="container">
          <header className="mb-4">
            <h3>Recommended Items</h3>
          </header>

          <div className="row">
            {products.map((product, index) => (
              <div
                className="h-75 col-xxl-3 col-xl-4 col-sm-6 col-12 "
                key={index}
              >
                <figure className="card card-product-grid">
                  <a
                    href={`/product/${encodeURIComponent(
                      HelperFunction.encrypt(product.id)
                    )}`}
                    className="img-wrap"
                  >
                    <img
                      src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${product.main_pics}`}
                      alt={product.productdisplayname}
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                        e.target.src = "/images/default.jpg"; // Set the default image
                      }}
                    />
                  </a>
                  <figcaption
                    className="p-3 border-top"
                    style={{
                      height: `${
                        currentUser && currentUser.show_Prices !== 0
                          ? "150px"
                          : "120px"
                      }`,
                      overflow: "hidden",
                    }}
                  >
                    <a
                      href={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="title mb-1 text-uppercase"
                    >
                      <strong className=" text-lg text-uppercase">
                        {product.productdisplayname}
                      </strong>
                    </a>
                    {currentUser && currentUser.show_Prices !== 0 && (
                      <>
                        <div className="price-wrap mb-1">
                          <strong
                            className={`price text-lg
                     text-decoration-line-through`}
                          >
                            MRP ₹{product.mrp}
                          </strong>
                        </div>
                        <div className="price-wrap mb-1">
                          <strong className={`price text-lg`}>
                            ₹{product.call_price} + GST
                          </strong>
                        </div>
                      </>
                    )}
                    <Link
                      to={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="title"
                    >
                      {product.product}
                    </Link>
                  </figcaption>
                  <div className="text-primary p-3 text-end">
                    <a
                      href={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="link-primary"
                    >
                      <p>Click for details...</p>
                    </a>
                  </div>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="padding-top">
        <div className="container">
          <header className="mb-4">
            <h3>Featured Items</h3>
          </header>

          <div className="row">
            {featuredProducts.map((product, index) => (
              <div
                className="h-75 col-xxl-3 col-xl-4 col-sm-6 col-12 "
                key={index}
              >
                <figure className="card card-product-grid">
                  <a
                    href={`/product/${encodeURIComponent(
                      HelperFunction.encrypt(product.id)
                    )}`}
                    className="img-wrap"
                  >
                    <img
                      src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${product.main_pics}`}
                      alt={product.productdisplayname}
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                        e.target.src = "/images/default.jpg"; // Set the default image
                      }}
                    />
                  </a>
                  <figcaption
                    className="p-3 border-top"
                    style={{
                      height: `${
                        currentUser && currentUser.show_Prices !== 0
                          ? "150px"
                          : "120px"
                      }`,

                      overflow: "hidden",
                    }}
                  >
                    <a
                      href={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="title mb-1 text-uppercase"
                    >
                      <strong className=" text-lg text-uppercase">
                        {product.productdisplayname}
                      </strong>
                    </a>

                    {currentUser && currentUser.show_Prices !== 0 && (
                      <>
                        <div className="price-wrap mb-1">
                          <strong
                            className={`price text-lg
                     text-decoration-line-through`}
                          >
                            MRP ₹{product.mrp}
                          </strong>
                        </div>
                        <div className="price-wrap mb-1">
                          <strong className={`price text-lg`}>
                            ₹{product.call_price} + GST
                          </strong>
                        </div>
                      </>
                    )}
                    <Link
                      to={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="title"
                    >
                      {product.product}
                    </Link>
                  </figcaption>
                  <div className=" p-3 text-end">
                    <a
                      href={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="link-primary"
                    >
                      <p className="">Click for details...</p>
                    </a>
                  </div>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
