import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import Content from "../../components/Home/Content";

const home = () => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="Type some info" />
        <meta name="author" content="Type name" />

        <title>Home </title>
        {/* <script
          src="//code.tidio.co/nljzkehim9zjihfrjocwm0lbp3okeboo.js"
          async=""
        ></script> */}
      </Helmet>
      <Navbar />
      <Content />
      {/* <script
        src="//code.tidio.co/nljzkehim9zjihfrjocwm0lbp3okeboo.js"
        async=""
      ></script> */}
      <Footer />
    </>
  );
};

export default home;
