import React, { useState } from "react";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import { bulkUploadProducts } from "../../../actions/modertor";

const BulkProductUpload = () => {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state

  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setErrorMessage("");
    setUploadSuccess(false);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement("a");
    link.href = "/bulk_product_template.csv";
    link.download = "bulk_product_template.csv";
    link.click();
  };

  const handleUpload = async () => {
    setErrorMessage("");

    if (!file) {
      setErrorMessage("Please select a file.");
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!["csv", "xlsx"].includes(fileExtension)) {
      setErrorMessage(
        "Invalid file format. Only CSV or XLSX files are allowed."
      );
      return;
    }

    setLoading(true); // Show loader
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data =
          fileExtension === "csv"
            ? e.target.result
            : new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: fileExtension === "csv" ? "string" : "array",
        });

        const sheetName = workbook.SheetNames[0];

        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet, { defval: null });
        // console.log(jsonData);
        if (jsonData.length === 0) {
          setErrorMessage("The uploaded file is empty.");
          setLoading(false);
          return;
        }

        await dispatch(bulkUploadProducts(jsonData))
          .then(() => {
            setUploadSuccess(true);
          })
          .catch((error) => {
            alert(error.message);
            throw new Error(error);
          });
      } catch (error) {
        setErrorMessage("An error occurred while processing the file");
        console.error(error.message);
      } finally {
        setLoading(false); // Hide loader
      }
    };

    if (fileExtension === "xlsx") {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }
  };

  return (
    <>
      <div className="container mt-5 position-relative">
        {loading && (
          <div className="position-fixed top-0 start-0 w-100 h-100 bg-light bg-opacity-75 d-flex align-items-center justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="card p-4 shadow">
          <h2 className="mb-4 text-center">Bulk Product Upload</h2>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <button
              onClick={handleDownloadTemplate}
              className="btn btn-outline-primary"
            >
              Download Template
            </button>
          </div>
          <div className="mb-3">
            <label htmlFor="fileInput" className="form-label">
              Upload a CSV/XLSX file:
            </label>
            <input
              type="file"
              id="fileInput"
              className="form-control"
              accept=".csv, .xlsx"
              onChange={handleFileChange}
            />
          </div>
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
          {uploadSuccess && (
            <div className="text-success">Upload successful!</div>
          )}
          <button
            onClick={handleUpload}
            className="btn btn-primary w-100"
            disabled={loading}
          >
            {loading ? "Processing..." : "Upload"}
          </button>
        </div>
      </div>
    </>
  );
};

export default BulkProductUpload;
