import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { registerUser } from "../../actions/User";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import UserService from "../../services/userService";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    user_code_d365: "",
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    companyPhone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    soneparSalesAssociates: "",
    natureOfBusiness: "",
    businessType: "",
    industry: "",
    estimatedMROSpend: "",
    numOfEmployees: "",
    productsServices: [],
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [userNameExist, setUserNameExist] = useState(true);
  const [emailExist, setEmailExist] = useState(true);
  const [phoneExist, setPhoneExist] = useState(true);
  const [businessTypesOptions, setBusinessTypesOptions] = useState([]);
  const [industriesOptions, setIndustriesOptions] = useState([]);
  const [mroSpendOptions, setMroSpendOptions] = useState([]);
  const [noOfEmployeesOptions, setNoOfEmployeesOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        productsServices: checked
          ? [...prevData.productsServices, value]
          : prevData.productsServices.filter((product) => product !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
      if (name === "password") validatePassword(value);
      if (name === "username") setUserNameExist(true);
      if (name === "companyPhone") setPhoneExist(true);
      if (name === "email") setEmailExist(true);
      if (name === "confirmPassword") validatePasswordMatch(value);
    }
  };

  useEffect(() => {
    UserService.getBusinessType().then((res) => {
      setBusinessTypesOptions(res.data);
    });
  }, []);

  useEffect(() => {
    UserService.getIndustries().then((res) => {
      setIndustriesOptions(res.data || []);
    });
  }, []);

  useEffect(() => {
    UserService.getMROOptions().then((res) => {
      setMroSpendOptions(res.data || []);
    });
  }, []);

  useEffect(() => {
    UserService.getEmployeeOptions().then((res) => {
      setNoOfEmployeesOptions(res.data || []);
    });
  }, []);

  useEffect(() => {
    UserService.getProductNeededOptions().then((res) => {
      setProductOptions(res.data || []);
    });
  }, []);

  const validatePassword = (password) => {
    const isValid = password.length >= 8 && /[\W_]/.test(password); // At least 8 chars and one special character
    setPasswordValid(isValid);
  };

  const validatePasswordMatch = (confirmPassword) => {
    setPasswordMatch(confirmPassword === formData.password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((passwordValid && passwordMatch && emailValid, phoneValid)) {
      // console.log(formData.productsServices);
      dispatch(registerUser(formData))
        .then(() => {
          // window.alert(
          //   "Your information has been submitted. You'll receive an email for further instructions."
          // );
          setMessage(
            "Thanks for your registration, Our sales team will revert back for the account status. Please check your email. If any query please write a mail to webshop@soneparindia.com"
          );
          setFormData({
            user_code_d365: "",
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            companyName: "",
            companyPhone: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            soneparSalesAssociates: "",
            natureOfBusiness: "",
            businessType: "",
            industry: "",
            estimatedMROSpend: "",
            numOfEmployees: "",
            productsServices: [],
          });
        })
        .catch((error) => {
          if (error.id === 1) {
            setUserNameExist(false);
          } else if (error.id === 2) {
            setEmailExist(false);
          } else if (error.id === 3) {
            setPhoneExist(false);
          }
        });
    } else {
      console.error("Please ensure all password requirements are met");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/; // Exactly 10 digits
    return phoneRegex.test(phone);
  };

  return (
    <>
      <Navbar />
      <div className="container mt-5 pb-5">
        <div className="card p-4">
          {message ? (
            <h2 className="mb-4 text-center">{message}</h2>
          ) : (
            <>
              <h2 className="mb-4 text-center">Create an Account</h2>
              <form onSubmit={handleSubmit} className="registration-form">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Sonepar Customer Code (Optional)</label>
                    <input
                      type="text"
                      name="user_code_d365"
                      value={formData.user_code_d365}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <h5>Personal Information</h5>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>
                      Username<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                      className="form-control"
                    />
                    {!userNameExist && (
                      <small className="text-danger">
                        Username Already Exist
                      </small>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label>
                      Email Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => {
                        handleChange(e);
                        setEmailValid(validateEmail(e.target.value));
                      }}
                      required
                      className={`form-control ${
                        !emailValid ? "is-invalid" : ""
                      }`}
                    />
                    {!emailValid && (
                      <small className="text-danger">
                        Please enter a valid email address.
                      </small>
                    )}
                    {!emailExist && (
                      <small className="text-danger">Email aleady exists</small>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>
                      Password<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        className={`form-control ${
                          !passwordValid ? "is-invalid" : ""
                        }`}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="btn btn-outline-secondary"
                      >
                        {showPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                    {!passwordValid && (
                      <small className="text-danger">
                        Password must be at least 8 characters long and include
                        a special character.
                      </small>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label>Confirm Password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                      className={`form-control ${
                        !passwordMatch ? "is-invalid" : ""
                      }`}
                    />
                    {!passwordMatch && (
                      <small className="text-danger">
                        Passwords do not match.
                      </small>
                    )}
                  </div>
                </div>

                <h5>Company Information</h5>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>
                      Company Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      required
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <label>
                      Company Phone<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="tel"
                      name="companyPhone"
                      value={formData.companyPhone}
                      onChange={(e) => {
                        handleChange(e);
                        setPhoneValid(validatePhoneNumber(e.target.value));
                      }}
                      required
                      className={`form-control ${
                        !phoneValid ? "is-invalid" : ""
                      }`}
                    />
                    {!phoneValid && (
                      <small className="text-danger">
                        Phone number must be exactly 10 digits.
                      </small>
                    )}
                    {!phoneExist && (
                      <small className="text-danger">
                        Phone Already Exists.
                      </small>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Sonepar Sales Associates</label>
                    <input
                      type="text"
                      name="soneparSalesAssociates"
                      value={formData.soneparSalesAssociates}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Nature of Business</label>
                    <input
                      type="text"
                      name="natureOfBusiness"
                      value={formData.natureOfBusiness}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Business Type</label>
                    <select
                      name="businessType"
                      value={formData.businessType}
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">Select Business Type</option>
                      {businessTypesOptions.map((item) => (
                        <option
                          key={"businessType" + item.name}
                          value={item.name}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>Industry</label>
                    <select
                      name="industry"
                      value={formData.industry}
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">Select Industry</option>
                      {industriesOptions.map((item) => (
                        <option
                          key={"industries" + item.name}
                          value={item.name}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label>Estimated MRO Spend</label>
                    <select
                      name="estimatedMROSpend"
                      value={formData.estimatedMROSpend}
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">Select Estimated MRO Spend</option>
                      {mroSpendOptions.map((item) => (
                        <option key={"mrospend" + item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>Number of Employees</label>
                    <select
                      name="numOfEmployees"
                      value={formData.numOfEmployees}
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">Select Number of Employees</option>
                      {noOfEmployeesOptions.map((item) => (
                        <option
                          key={"noofemployees" + item.name}
                          value={item.name}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <h5>Products or Services Needed</h5>
                <div className="row mb-3">
                  <div className="col-md-6">
                    {productOptions.map((item) => (
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="productsServices"
                          value={item.name}
                          onChange={handleChange}
                          className="form-check-input"
                          id={item.name}
                        />
                        <label className="form-check-label" htmlFor={item.name}>
                          {item.name}
                        </label>
                      </div>
                    ))}

                    {/* Add more checkboxes as needed */}
                  </div>
                </div>

                <p>
                  By clicking "Submit" you are agreeing to the Sonepar's{" "}
                  <a href="/termsandcondition">
                    Privacy Policy and Terms & Conditions
                  </a>
                  .
                </p>
                <button type="submit" className="btn btn-primary w-100">
                  Create Account
                </button>
              </form>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RegistrationForm;
