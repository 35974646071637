import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../services/userService";
import { Modal } from "react-bootstrap";
import { addToCart } from "../../actions/cart";
import HelperFunction from "../../services/helperFunction";
import "./product.css";
import { useNavigate } from "react-router-dom";
// Product Detail Component
const ProductDetail = ({ id, setCatId }) => {
  const [product, setProduct] = useState({});
  const [moq, setMoq] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const { user: currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const encryptedId = HelperFunction.decrypt(decodeURIComponent(id));
    UserService.getProductById({ id: encryptedId }).then((response) => {
      setProduct(response.data.product);
      setCatId(response.data.product.cat_id);
      setMoq(response.data.product.moq);
    });
  }, [id]);

  const handleImageClick = (imagePath) => {
    setSelectedImage(`${process.env.REACT_APP_BACKEND_SERVER}/${imagePath}`);
    setShowModal(true);
  };

  const changeMoq = () => {
    if (moq > product.moq) {
      setMoq(moq - 1);
    }
  };

  const addToCartHandler = () => {
    if (!currentUser) {
      navigate("/login");
    } else {
      dispatch(
        addToCart({
          id: product.id,
          name: product.productdisplayname,
          price: product.call_price,
          tax: product.gst,
          quantity: moq,
          image: product.main_pics,
          moq: product.moq,
        })
      ).then(() => {
        alert("Item added to cart successfully.");
      });
    }
  };

  // const buyOrderHandle = () => {
  //   addToCartHandler();
  //   navigate("/cart");
  // };

  return (
    <section className="padding-y">
      <div className="container">
        <div className="row">
          <aside className="col-lg-6">
            <figure className="gallery-wrap">
              <button
                className="img-main-wrap mb-3 img-thumbnail"
                style={{
                  height: "520px",
                  width: "720px",
                  border: "none",
                  background: "transparent",
                }}
                onClick={(e) => {
                  handleImageClick("images/" + product.main_pics);
                }}
              >
                {product?.main_pics && (
                  <img
                    src={
                      process.env.REACT_APP_BACKEND_SERVER +
                      "/images/" +
                      product.main_pics
                    }
                    className="h-100 img-cover"
                    alt="Product"
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                      e.target.src = "/images/default.jpg"; // Set the default image
                    }}
                  />
                )}
              </button>

              <div className="thumbs-wrap text-center overflow-auto text-nowrap">
                {product?.images?.map((image) => {
                  if (image.type === "image") {
                    return (
                      <button
                        key={image.id}
                        className="item-thumb"
                        style={{
                          border: "none",
                          background: "transparent",
                          padding: 0,
                        }}
                        onClick={(e) => {
                          handleImageClick(image.path);
                        }}
                      >
                        <img
                          className="img-thumbnail size-60x60"
                          height="60"
                          src={
                            process.env.REACT_APP_BACKEND_SERVER +
                            "/" +
                            image.path
                          }
                          alt="Thumbnail"
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                            e.target.src = "/images/default.jpg"; // Set the default image
                          }}
                        />
                      </button>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
            </figure>
          </aside>
          <main className="col-lg-6">
            <article className="ps-lg-3 text-uppercase">
              <h4 className="title text-dark">{product.productdisplayname}</h4>
              {currentUser && currentUser.show_Prices !== 0 && (
                <div className="mb-2">
                  <>
                    <p>
                      ₹
                      {(
                        +product.call_price +
                        +(+product.gst * 0.01 * +product.call_price)
                      ).toFixed(2)}{" "}
                      (incl. of taxes)
                    </p>
                    <p>
                      <var className={`price h4 `}>
                        {" "}
                        &nbsp;₹{product.call_price} +{" "}
                        <var className={`h6`}>{product.gst}%GST </var>
                      </var>
                      <span className="text-muted">/per {product.uom}</span>
                    </p>
                    <p>
                      MRP{" "}
                      <var className="text-decoration-line-through">
                        ₹{product.mrp}{" "}
                      </var>
                      <var className="border">
                        &nbsp;{" "}
                        {(
                          ((+product.mrp -
                            product.call_price -
                            +product.gst * 0.01 * +product.call_price) /
                            +product.mrp) *
                          100
                        ).toFixed(2)}
                        % OFF &nbsp;
                      </var>
                    </p>
                  </>
                </div>
              )}
              <p>{product.specifications}</p>
              <hr />

              <div className="row mb-3">
                <div className="col-md-6 col-6 mb-2">
                  <label className="form-label d-block">Quantity</label>
                  <div className="input-group input-spinner-custom">
                    <button
                      className="btn btn-icon btn-light"
                      onClick={changeMoq}
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="#999"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 13H5v-2h14v2z"></path>
                      </svg>
                    </button>
                    <input
                      className="form-control w-100 text-center"
                      placeholder="0"
                      value={moq}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Ensure input is a valid number and not empty
                        if (/^\d+$/.test(value)) {
                          const numericValue = Math.max(
                            parseInt(value, 10),
                            product.moq
                          ); // Ensure it's at least product.moq
                          setMoq(numericValue);
                        } else if (value === "") {
                          setMoq(product.moq); // Reset to product.moq if the input is cleared
                        }
                      }}
                      onBlur={() => {
                        // Ensure the input has a valid value on blur (in case the user leaves it empty)
                        if (!moq || moq < product.moq) {
                          setMoq(product.moq);
                        }
                      }}
                    />
                    <button
                      className="btn btn-icon btn-light"
                      onClick={() => setMoq(moq + 1)}
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="#999"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              {/* <button onClick={buyOrderHandle} className="btn btn-warning">
                Buy now
              </button> */}
              <button onClick={addToCartHandler} className="btn btn-primary">
                <i className="me-1 fa fa-shopping-basket"></i> Add to sales
                quotation
              </button>
            </article>
          </main>
        </div>
      </div>

      {/* Modal to show enlarged image */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Product Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Enlarged Product"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ProductDetail;
