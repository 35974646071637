import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import UserService from "../../services/userService";
import { Button, Form } from "react-bootstrap";
const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.companyName.trim())
      newErrors.companyName = "Company name is required.";
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone must be 10 digits.";
    }
    if (!formData.message.trim()) newErrors.message = "Message is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    try {
      if (validate()) {
        await UserService.submitQueryForm(formData);
        alert(
          "Your message has been sent successfully, Our team will contact you regarding this as soon as possible"
        );

        setFormData({
          name: "",
          email: "",
          phone: "",
          companyName: "",
          message: "",
        });
      }
    } catch (error) {
      alert("Something went wrong, Please try again.");
      console.log(error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="contact-us-page">
        {/* Header Section */}
        <section className="contact-header text-center py-5 bg-light">
          <div className="container">
            <h1 className="display-4 fw-bold">Contact Us</h1>
            <p className="lead">
              We'd love to hear from you! Please reach out with any questions or
              comments.
            </p>
          </div>
        </section>

        {/* Contact Information and Form Section */}
        <section className="contact-info py-5">
          <div className="container">
            <div className="row">
              {/* Contact Details */}
              <div className="col-md-6 mb-4">
                <h2 className="h3 fw-bold">Get in touch</h2>
                <p>
                  <strong>Address:</strong>
                  <br />
                  Plot No.01, Gokul Garden, Sriperumbudur Road, Melnallathur,
                  <br />
                  Tiruvallur Chennai-602002,
                  <br />
                  Tamil Nadu, India.
                </p>
                <p>
                  {/* <strong>Phone:</strong> (123) 456-7890 */}
                  <br />
                  <strong>Tel:</strong> +919560013353
                </p>
                <p>
                  {/* <strong>Phone:</strong> (123) 456-7890 */}
                  <br />
                  <strong>Email:</strong> webshop@soneparindia.com
                </p>
              </div>

              {/* Contact Form */}
              <div className="col-md-6">
                <h2 className="h3 fw-bold">Send Us a Message</h2>
                <Form onSubmit={onsubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      isInvalid={!!errors.companyName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      isInvalid={!!errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button variant="primary" onClick={onSubmit}>
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </section>

        {/* Map Section */}
        {/* <section className="contact-map py-5 bg-light">
          <div className="container text-center">
            <h2 className="h3 fw-bold mb-4">Our Location</h2>
            <iframe
              title="Company Location"
              src="https://www.google.com/maps/embed?pb=!1m18!..." // Add your map link here
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </section> */}
      </div>
      <Footer />
    </>
  );
};

export default ContactUsPage;
