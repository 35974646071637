import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;

const handleDownloadPDF = (selectedOrder, myProfileData) => {
  if (!selectedOrder) return;
  // console.log(myProfileData);
  // Placeholder company information
  const companyInfo = {
    name: "Sonepar India",
    address:
      "Plot No. 229/239, Village-Kherki Daula Sector 76, Gurugram, Haryana, 122004, India",
    phone: "+91-9971411499",
    email: "support.telecom@soneparindia.com",
  };

  // Customer (user) company details
  const customerInfo = {
    name: myProfileData.user_company_name || "Customer Company Name",
    address: `${myProfileData.city || "City"}, ${
      myProfileData.state || "State"
    }, ${myProfileData.country || "Country"}`,
    phone: myProfileData.mobile_number || "Customer Phone",
    email: myProfileData.email_add || "customer@example.com",
  };

  // Prepare the document definition
  const docDefinition = {
    // a string or { width: number, height: number }
    pageSize: "A4",

    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: 40,
    content: [
      { text: "Sales Order", style: "header", alignment: "center" },
      {
        columns: [
          [
            { text: companyInfo.name, style: "subheader" },
            { text: companyInfo.address },
            { text: `Phone: ${companyInfo.phone}` },
            { text: `Email: ${companyInfo.email}` },
          ],
          // [{ text: " " }, { text: " " }, { text: " " }],
          [
            { text: "Bill To:", style: "subheader" },
            { text: customerInfo.name },
            { text: customerInfo.address },
            { text: `Phone: ${customerInfo.phone}` },
            { text: `Email: ${customerInfo.email}` },
          ],
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: [
            "*",
            "auto",
            "auto",
            //  "auto", "auto", "auto"
          ],
          body: [
            [
              "Product Code",
              "Product Name",
              "Quantity",
              // "Net Amount(₹)",
              // "GST(₹)",
              // "Total Amount(₹)",
            ],
            ...selectedOrder.items.map((item) => [
              item.product_code,
              item.product_name,
              item.item_quantity,
              // `${item.item_net_amount}`,
              // `${item.item_gst}`,
              // `${item.item_total_amount}`,
            ]),
          ],
        },
      },
      { text: " " },
      {
        columns: [
          { text: " " },
          {
            table: {
              body: [
                ["Total Quantity:", selectedOrder.total_quantity],
                // ["Total Amount:", `₹${selectedOrder.order_total_amount}`],
                // ["Total GST:", `₹${selectedOrder.order_gst}`],
                // ["Total Net Amount:", `₹${selectedOrder.order_net_amount}`],
              ],
            },
            layout: "noBorders",
          },
        ],
      },
    ],
    styles: {
      header: { fontSize: 22, bold: true },
      subheader: { fontSize: 14, bold: true },
    },
  };

  // Generate and download the PDF
  pdfMake.createPdf(docDefinition).print();
};

export default handleDownloadPDF;
