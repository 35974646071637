// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// Import Bootstrap Bundle JS (which includes Popper.js)

import "./stylings/css/bootstrap.css?v=2.0";

import "./stylings/css/ui.css?v=2.0";
import "./stylings/css/responsive.css?v=2.0";

import "./stylings/fonts/fontawesome/css/all.min.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { RouterProvider } from "react-router-dom";
import router from "./router";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary"; // Import the ErrorBoundary
import { useEffect } from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
export default function App() {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src = "//code.tidio.co/mbuqxgz1gxrklsqnd227iix0om3evzb0.js"; // Replace with your script's URL or file path
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup: Remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className="bg-light">
      <ErrorBoundary>
        <FloatingWhatsApp
          avatar="/Logo_whatsapp.png"
          phoneNumber="+919560013353"
          accountName="Sonepar India"
        />
        <RouterProvider router={router} />
      </ErrorBoundary>
    </div>
  );
}
