import React, { useEffect, useState } from "react";
import UserService from "../../services/userService";
import HelperFunction from "../../services/helperFunction";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PopularCategories = ({ catId }) => {
  const [products, setProducts] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  useEffect(() => {
    UserService.getProducts({
      limit: 3,
      random: true,
      selectedCategories: [catId],
    }).then((response) => {
      setProducts(response.data.products);
    });
  }, [catId]);

  return (
    <section className="padding-y bg-light">
      <div className="container">
        <header className="section-heading">
          <h3>Related Products</h3>
        </header>

        <div className="row">
          {products.map((product, index) => (
            <div className="col-lg-4" key={index}>
              <div>
                <figure className="card card-product-grid">
                  <a
                    href={`/product/${encodeURIComponent(
                      HelperFunction.encrypt(product.id)
                    )}`}
                    className="img-wrap"
                  >
                    <img
                      src={`${process.env.REACT_APP_BACKEND_SERVER}/images/${product.main_pics}`}
                      alt={product.productdisplayname}
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop in case the default image also fails
                        e.target.src = "/images/default.jpg"; // Set the default image
                      }}
                    />
                  </a>
                  <figcaption
                    className="p-3 border-top"
                    style={{
                      height: `${
                        currentUser && currentUser.show_Prices !== 0
                          ? "150px"
                          : "120px"
                      }`,

                      overflow: "hidden",
                    }}
                  >
                    <a
                      href={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="title mb-1"
                    >
                      <strong className=" text-lg text-uppercase">
                        {product.productdisplayname}
                      </strong>
                    </a>
                    {currentUser && currentUser.show_Prices !== 0 && (
                      <div className="price text-lg mb-2 fw-bold">
                        ₹ {product.call_price}
                      </div>
                    )}

                    <Link
                      to={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="title"
                    >
                      {product.product}
                    </Link>
                  </figcaption>
                  <div className="text-primary p-3 text-end">
                    <a
                      href={`/product/${encodeURIComponent(
                        HelperFunction.encrypt(product.id)
                      )}`}
                      className="link-primary"
                    >
                      <p>Click for details...</p>
                    </a>
                  </div>
                </figure>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PopularCategories;
