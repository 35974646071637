import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../services/userService";
import { useNavigate } from "react-router-dom";
import HelperFunction from "../../services/helperFunction";
import { logout } from "../../actions/auth";

const Navbar = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { count } = useSelector((state) => state.cart);
  const [total, setTotal] = useState(null);
  const [profileState, setProfileState] = useState({});
  const [brands, setBrands] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    UserService.getBrand({ limit: 10 }).then((response) => {
      setBrands(response.data.brands);
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      UserService.getCart().then((cartItems) => {
        const totalQuantity = cartItems.data.cart.reduce(
          (sum, item) => +sum + +item.quantity,
          0
        );
        setTotal(totalQuantity);
      });
    }
  }, [currentUser, count]);

  useEffect(() => {
    const fetchUser = async () => {
      if (currentUser) {
        var link = currentUser?.link !== "/" ? currentUser.link : "/myprofile";

        setProfileState({ textState: "Profile", link: link });
      } else {
        setProfileState({ textState: "Login", link: "/login" });
      }
    };

    fetchUser();
  }, [currentUser]);

  const handleBrandClick = (id, brand_name, banner) => {
    // Pass the state (example: a product ID or object) while navigating
    navigate(
      `/shop?brandId=${encodeURIComponent(
        HelperFunction.encrypt(id)
      )}&brandName=${encodeURIComponent(
        HelperFunction.encrypt(brand_name)
      )}/&banner=${banner}`
    );
  };

  const handleSearchClick = () => {
    // Pass the state (example: a product ID or object) while navigating
    navigate(
      `/shop?searchTerm=${encodeURIComponent(
        HelperFunction.encrypt(searchTerm)
      )}`
    );
  };

  const handleLogout = () => {
    dispatch(logout())
      .then(() => navigate("/login"))
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <header className="section-header">
      <section className="header-main border-bottom py-3">
        <div className="container ">
          <div className="row gx-2 gy-3 ">
            <div className="col-lg col-md col-6 flex-grow-0">
              <a href="/" className="brand-wrap me-4">
                <img
                  alt="sonepar_logo"
                  className="logo"
                  height={100}
                  src="/logo-white-2.png"
                />
              </a>
            </div>

            <div className="col-lg col-md order-lg-last">
              <div className="float-md-end searchbar-class">
                {currentUser && (
                  <a href="/orders" className="btn btn-dark me-1">
                    <i className="fas fa-shopping-bag"></i>
                    <span className="ms-1 d-none d-sm-inline-block">
                      My Quotations
                    </span>
                  </a>
                )}

                <a href={profileState.link} className="btn btn-dark">
                  <i className="fa fa-user"></i>
                  <span className="ms-1 d-none d-sm-inline-block">
                    {profileState.textState}
                  </span>
                </a>

                <a href="/cart" className="btn btn-dark ms-2">
                  <i className="fa fa-shopping-cart me-1"></i> Quotation
                  <span className="badge bg-danger ms-2">
                    {total !== 0 ? total : ""}
                  </span>
                </a>

                {currentUser && (
                  <button
                    onClick={handleLogout}
                    className="btn btn-outline-secondary ms-2"
                  >
                    Logout
                  </button>
                )}

                <button
                  className="btn btn-dark d-md-none ms-2 float-end"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#main_nav"
                  aria-controls="main_nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  Menu
                </button>
              </div>
            </div>

            <div className="col-lg-2 col-xl-3 col-xxl-4 col-md-12 order-lg-3">
              <div className="input-group searchbar-class">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button
                  onClick={handleSearchClick}
                  className="input-group-btn btn-icon btn btn-light"
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <nav className="navbar-dark py-0 py-md-2 navbar-expand-md navbar-dark border-bottom">
        <div className="container ">
          <div className="navbar-collapse collapse" id="main_nav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  {" "}
                  Home{" "}
                </a>
              </li>
              <li className="nav-item dropdown">
                <p
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Brands
                </p>
                <ul className="dropdown-menu">
                  {brands.map((brand, index) => (
                    <li
                      key={brand.brand_name + index}
                      onClick={() =>
                        handleBrandClick(
                          brand.id,
                          brand.brand_name,
                          brand.banner
                        )
                      }
                    >
                      <p className="dropdown-item" href={`/${brand.brand}`}>
                        {" "}
                        {brand.brand_name}
                      </p>{" "}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/shop">
                  {" "}
                  Products{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/cart">
                  {" "}
                  Quotation{" "}
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/aboutus">
                  {" "}
                  About Us{" "}
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/contact">
                  {" "}
                  Contact Us{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
