import React, { useEffect, useMemo, useState } from "react";
import ModService from "../../../services/modService"; // Assuming you have this service for API requests
import { useTable, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";
import { updateClientStatus } from "../../../actions/modertor";
import { Modal } from "react-bootstrap"; // Import Modal from bootstrap
import moment from "moment";
import { useDispatch } from "react-redux";
const PendingClientListPage = () => {
  const [clients, setClients] = useState([]); // Store clients data
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const [totalPages, setTotalPages] = useState(1); // For pagination
  const [searchTerm, setSearchTerm] = useState(""); // For searching clients
  const [loading, setLoading] = useState(false); // To show loading state
  const [showModal, setShowModal] = useState(false); // For modal visibility
  const [selectedClient, setSelectedClient] = useState(null); // For selected client data
  const [totalClients, setTotalClients] = useState(0);
  const dispatch = useDispatch();
  // Fetch clients from the backend
  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true); // Set loading to true when the fetch starts
      try {
        const response = await ModService.getClients({
          page: currentPage + 1, // API expects pages starting from 1
          limit: 10, // Limiting the number of records per page
          status: "pending",
        });
        setClients(response.data.clients); // Set fetched clients data
        setTotalPages(response.data.totalPages); // Set total number of pages
        setTotalClients(response.data.count);
      } catch (error) {
        console.error("Error fetching clients", error);
      }
      setLoading(false); // Set loading to false after the data is loaded
    };
    fetchClients();
  }, [currentPage]);

  // Function to refresh the list of clients
  const refreshClients = async () => {
    setLoading(true);
    try {
      const response = await ModService.getClients({
        page: currentPage + 1,
        limit: 10,
        status: "pending",
      });
      setClients(response.data.clients);
      setTotalPages(response.data.totalPages);
      setTotalClients(response.data.count);
    } catch (error) {
      console.error("Error refreshing clients", error);
    }
    setLoading(false);
  };

  // Column definitions for react-table
  const columns = useMemo(
    () => [
      { Header: "Client Code", accessor: "user_code_d365" },
      {
        Header: "Cust. Name",
        accessor: "fullName", // Virtual accessor (not directly in the data)
        Cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`, // Combine first and last name
      },
      { Header: "username", accessor: "user_name" },
      { Header: "Company", accessor: "user_company_name" },
      { Header: "Phone", accessor: "mobile_number" },
      { Header: "Email", accessor: "email_add" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => (
          <span
            className={`badge text-white ${
              cell.value === "approved"
                ? "bg-success"
                : cell.value === "pending"
                ? "bg-warning"
                : "bg-danger"
            }`}
          >
            {cell.value}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-link text-primary p-0 me-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
              onClick={() => handleViewClient(row.original)}
            >
              View
            </button>
            |
            <button
              className="btn btn-link text-primary p-0 ms-2 me-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
              onClick={() => handleApproveClient(row.original)}
            >
              Approve
            </button>
            |
            <button
              className="btn btn-link text-primary p-0 ms-2"
              style={{ display: "inline", verticalAlign: "baseline" }}
              onClick={() => handleRejectClient(row.original)}
            >
              Reject
            </button>
          </>
        ),
      },
    ],
    []
  );

  // Data for react-table
  const data = useMemo(() => clients, [clients]);

  // Table instance from react-table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data },
      useSortBy // Sorting functionality
    );

  // Handle pagination
  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Change page when user clicks pagination
  };

  // Handle search
  const handleSearch = async () => {
    setLoading(true); // Set loading to true when search starts
    try {
      const response = await ModService.getClients({
        page: currentPage + 1,
        limit: 10,
        search: searchTerm,
        status: "pending",
      });
      setClients(response.data.clients);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error searching clients", error);
    }
    setLoading(false); // Set loading to false after search completes
  };

  // Handle client view in modal
  const handleApproveClient = async (client) => {
    console.log(client);
    if (!client.user_code_d365) {
      alert("Please add User client code before approving");
      return;
    }

    const confirmApprove = window.confirm(
      `Do you want to approve ${client.user_name}?`
    );

    if (confirmApprove) {
      dispatch(updateClientStatus({ id: client.user_id, status: "approved" }))
        .then(() => {
          alert("Client Approved");
          refreshClients();
        })
        .catch((error) => {
          alert("Something went wrong! Please try again");
        });
    } else {
      // User clicked "Cancel", do nothing

      return;
    }
  };

  // Handle client view in modal
  const handleRejectClient = async (client) => {
    const confirmApprove = window.confirm(
      `Do you want to reject ${client.user_name}?`
    );

    if (confirmApprove) {
      dispatch(
        updateClientStatus({
          id: client.user_id,
          status: "rejected",
        })
      )
        .then(() => {
          alert("Client rejected");
          refreshClients();
        })
        .catch(() => {
          alert("Something went wrong! Please try again");
        });
    } else {
      // User clicked "Cancel", do nothing
      return;
    }
  };

  // Handle client view in modal
  const handleViewClient = (client) => {
    setSelectedClient(client); // Set the client details in state
    setShowModal(true); // Open modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close modal
    setSelectedClient(null); // Clear selected client data
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Pending Client Listing Page</h1>
      </div>

      {/* Search Bar */}
      <div className="input-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Search clients..."
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(); // Trigger search when Enter is pressed
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-outline-secondary" onClick={handleSearch}>
          Search
        </button>
      </div>

      <div className="input-group mb-4">
        <label>
          {" "}
          Total Pending Clients: &nbsp;<strong>{totalClients} </strong>
        </label>
      </div>

      {/* Loader */}
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {/* Client Table */}
          <table
            {...getTableProps()}
            className="table table-hover table-bordered"
          >
            <thead className="table">
              {headerGroups.map((headerGroup, headerGroupIndex) => {
                const { key: headerGroupKey, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps(); // Extract key from headerGroup
                return (
                  <tr
                    key={headerGroupKey || headerGroupIndex}
                    {...headerGroupProps}
                  >
                    {headerGroup.headers.map((column, columnIndex) => {
                      const { key: columnKey, ...columnProps } =
                        column.getHeaderProps(column.getSortByToggleProps()); // Extract key from column
                      return (
                        <th
                          key={columnKey || columnIndex} // Fall back to index if key is not available
                          {...columnProps}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row); // Prepare the row for rendering
                return (
                  <tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} key={cell.column.id}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}

      {/* Modal for viewing client details */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Client Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedClient ? (
            <div>
              <h5>Client ID: {selectedClient.user_code_d365}</h5>
              <p>
                <strong>username:</strong> {selectedClient.user_name}
              </p>
              <p>
                <strong>First Name:</strong> {selectedClient.firstName}
              </p>
              <p>
                <strong>Last Name:</strong> {selectedClient.lastName}
              </p>
              <p>
                <strong>Company Name:</strong>{" "}
                {selectedClient.user_company_name}
              </p>
              <p>
                <strong>Phone:</strong> {selectedClient.mobile_number}
              </p>
              <p>
                <strong>Email:</strong> {selectedClient.email_add}
              </p>
              <p>
                <strong>City:</strong> {selectedClient.city}
              </p>
              <p>
                <strong>State:</strong> {selectedClient.state}
              </p>
              <p>
                <strong>Country:</strong> {selectedClient.country}
              </p>
              <p>
                <strong>nature_of_business:</strong>{" "}
                {selectedClient.nature_of_business}
              </p>
              <p>
                <strong>no_of_employee:</strong> {selectedClient.no_of_employee}
              </p>
              <p>
                <strong>product_looking_for:</strong>{" "}
                {selectedClient.product_looking_for}
              </p>
              <p>
                <strong>Business:</strong> {selectedClient.select_business_type}
              </p>
              <p>
                <strong>Industry:</strong> {selectedClient.select_industry}
              </p>
              <p>
                <strong>sonepar_sales_associates:</strong>{" "}
                {selectedClient.sonepar_sales_associates}
              </p>
              <p>
                <strong>Status:</strong>{" "}
                <span
                  className={`badge text-white ${
                    selectedClient.status === "approved"
                      ? "bg-success"
                      : selectedClient.status === "pending"
                      ? "bg-warning"
                      : "bg-danger"
                  }`}
                >
                  {selectedClient.status}
                </span>
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {moment(selectedClient.created_at).format("DD MMM YYYY")}
              </p>
              <p>
                <strong>Updated At:</strong>{" "}
                {moment(selectedClient.updated_at).format("DD MMM YYYY")}
              </p>
              <p>
                <strong>Show Prices:</strong>{" "}
                {selectedClient.show_Prices ? "True" : "False"}
              </p>
            </div>
          ) : (
            <p>No client selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PendingClientListPage;
