import React, { useEffect, useState } from "react";

import Navbar from "../../components/Navbar/Navbar";
// import BreadcrumbSection from "../../components/BreadcrumbSection";
// import SubscribeSection from "../../components/SubscribeSection";
import Footer from "../../components/Footer/Footer";
import ContentSection from "../../components/Shop/ContentSection";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import HelperFunction from "../../services/helperFunction";

const Shop = () => {
  const location = useLocation();
  const [brandData, setBrandData] = useState(location.state || null);
  const [categoryData, setCategoryData] = useState(location.state || null);
  const [searchTerm, setSearchTerm] = useState(location.state || null);
  const [brandBannerImage, setBrandBannerImage] = useState(null);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const brandId = params.get("brandId");
    const brandName = params.get("brandName");
    const banner = params.get("banner");
    const categoryId = params.get("categoryId");
    const categoryName = params.get("categoryName");
    const searchTerm = params.get("searchTerm");
    if (brandId && brandName) {
      setBrandData({
        id: HelperFunction.decrypt(decodeURIComponent(brandId)),
        brand_name: HelperFunction.decrypt(decodeURIComponent(brandName)),
        banner: banner,
      });
    } else {
      setBrandData(null);
    }

    if (categoryId && categoryName) {
      setCategoryData({
        id: HelperFunction.decrypt(decodeURIComponent(categoryId)),
        cat_name: HelperFunction.decrypt(decodeURIComponent(categoryName)),
      });
    } else {
      setCategoryData(null);
    }

    if (searchTerm) {
      setSearchTerm(HelperFunction.decrypt(decodeURIComponent(searchTerm)));
    } else {
      setSearchTerm(null);
    }
  }, [location.search]);

  // ... rest of your component

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="Type some info" />
        <meta name="author" content="Type name" />

        <title>Sonepar India</title>
      </Helmet>
      <Navbar />

      {/* <BreadcrumbSection /> */}
      <ContentSection
        brandBannerImage={brandBannerImage}
        brandData={brandData}
        setBrandBannerImage={setBrandBannerImage}
        setBrandData={setBrandData}
        categoryData={categoryData}
        setCategoryData={setCategoryData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <Footer />
    </>
  );
};

export default Shop;
