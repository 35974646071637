import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { store } from "./store";
import { Provider } from "react-redux";
import Setup from "./services/setupInterceptors";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    {/* <script
      src="//code.tidio.co/nljzkehim9zjihfrjocwm0lbp3okeboo.js"
      async=""
    ></script> */}
  </Provider>
);

Setup(store);
