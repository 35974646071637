import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const TermsAndCondition = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          padding: "20px",
          maxWidth: "800px",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <div>
          <h1>Terms and Conditions</h1>
          {/* <p>Last updated: December 4, 2024</p> */}
          <br />
          <br />
          <br />
          <p>
            The requirement raised through the portals will be executed as per
            separate contractual Purchase order/Agreement between the Customer &
            Sonepar India.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndCondition;
